import * as React from "react";

import {
  AddPatientChartModal,
  ChartNotesModal,
  ChartPicker,
  OrderChartNotesModal,
  SidebarChartPatientCard,
  VitalsTimerBadge,
  useChartSectionNotesCount,
  useChartSidebarSectionIndicator,
  useChartSidebarSharedSectionIndicator,
} from "modules/charts-shared";
import {
  AuditCommentsSectionIndicator,
  AuditCommentsSharedSectionIndicator,
} from "./audit-comments";
import {
  BackButton,
  Counter,
  Loading,
  NavigationButton,
  NavigationButtonContent,
  NavigationButtonContentProps,
  Sidebar,
  SidebarActions,
  SidebarBottom,
  SidebarContent,
  SidebarHeader,
  SidebarUserView,
  WarningDialog,
} from "shared";
import {
  Box,
  Button,
  ChakraProps,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { EmrNotes, EmrPerson } from "@medstonetech/slate-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AddRounded, BubbleChatIndicator, Car, Home } from "icons";
import { ChartSectionHeaderMenu } from "./ChartSectionHeader";
import { DiagramCanvasModal } from "./diagrams/DiagramCanvasModal";
import { FaqButton } from "modules/faq/components";
import { OrderFormElementType, OrderGroup } from "enums";
import { SharedChartNotesModal } from "./chart-notes/SharedChartNotesModal";
import { useDiagramsCounter } from "./diagrams/api";
import { useChartTrackingContext, usePermissions } from "contexts";
import { useScrollbarWidth } from "hooks";
import {
  useMedsAndIvCounters,
  useTreatmentsCounters,
} from "modules/triage/api";
import { useEncounterOrderCounters } from "modules/orders/api";
import { useEKGCounters } from "modules/provider/api";
import { SignedSectionButton } from "./summary-signed-section-button";
import { useUpdateAuditStatus } from "modules/audits/api";
import { genericErrors } from "messages";
import { extractApiErrorMessage } from "utils";
import { NURSE_CHARTS } from "modules/triage/constants";
import { PROVIDER_CHARTS } from "modules/provider/constants";

type ChartsSidebarProps = {
  children?: React.ReactNode;
};
type ChartNavigationButtonNotesProps = {
  hasNotes: true;
} & (
  | {
      notesType: "general" | "shared";
    }
  | {
      notesType: "order";
      orderType: OrderGroup;
    }
);

type ChartNavigationButtonProps = {
  sectionId: string;
  encounterId: string;
  to: string;
  chartCode: string;
  hasDiagrams?: boolean;
  isAccessible?: boolean;
  isShared?: boolean;
  showIvMedicationCounters?: boolean;
  showTreatmentsCounters?: boolean;
  showOrderCounters?: OrderFormElementType;
  showEKGCounters?: boolean;
  isSaved?: boolean;
  hasPendingOrdersCancellation?: boolean;
  showSignedSections?: boolean;
} & { hasAudits?: boolean } & (
    | { hasNotes?: false }
    | ChartNavigationButtonNotesProps
  ) &
  NavigationButtonContentProps;

type AddNewChartButtonProps = {
  encounterId: string;
  defaultIsOpen?: {
    value: boolean;
    chartType: string;
  };
};

function findChartTypeFromPathname(pathname: string): string {
  const nurseChartCodes = Object.keys(NURSE_CHARTS);
  const providerChartCodes = Object.keys(PROVIDER_CHARTS);

  const isNurse = nurseChartCodes.some((code) => pathname.includes(code));
  if (isNurse) {
    return "Nurse";
  }

  const isProvider = providerChartCodes.some((code) => pathname.includes(code));
  if (isProvider) {
    return "Provider";
  }

  return "";
}

function AddNewChartButton(props: AddNewChartButtonProps) {
  const { encounterId, defaultIsOpen } = props;
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: defaultIsOpen?.value,
  });

  return (
    <>
      <Button
        fontSize="0.9375rem"
        color="blue"
        display="flex"
        variant="label"
        alignItems="center"
        marginLeft="7px"
        onClick={onOpen}
      >
        <Icon as={AddRounded} fontSize="1.125rem" marginRight="16px" />
        Add New Chart
      </Button>
      <AddPatientChartModal
        isOpen={isOpen}
        onClose={onClose}
        encounterId={encounterId}
        defaultChartType={defaultIsOpen?.chartType}
      />
    </>
  );
}

type DiagramsButtonProps = {
  encounterId: string;
  chartCode: string;
};

function DiagramsButton(props: DiagramsButtonProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { chartCode, encounterId } = props;
  const { data, isLoading } = useDiagramsCounter(encounterId, chartCode);

  const diagramsCounter = data?.data || 0;

  return (
    <>
      <Box position="relative">
        <IconButton
          aria-label="notes"
          isLoading={isLoading}
          icon={
            <Box
              width="1.25rem"
              height="1.25rem"
              borderRadius="50%"
              bg={diagramsCounter > 0 ? "blue" : "gray.450"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={EmrPerson}
                color="gray.100"
                width="0.4375rem"
                height="0.5.875rem"
              />
            </Box>
          }
          variant="icon"
          fontSize="1.4375rem"
          minWidth="unset"
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
        />
        {diagramsCounter > 0 && (
          <Counter
            size="sm"
            count={diagramsCounter}
            position="absolute"
            right="-2px"
            top="-2px"
          />
        )}
      </Box>
      <React.Suspense fallback={"Loading..."}>
        <DiagramCanvasModal
          isOpen={isOpen}
          diagrams={[]}
          onClose={onClose}
          {...props}
        />
      </React.Suspense>
    </>
  );
}

type CharNotesButtonProps = {
  onOpen: () => void;
  encounterId: string;
  chartCode: string;
  sectionCode: string;
};

function ChartNotesButton(props: CharNotesButtonProps) {
  const { onOpen, encounterId, chartCode, sectionCode } = props;

  const { data } = useChartSectionNotesCount({
    encounterId,
    chartCode,
    sectionCode,
  });

  const sectionNotesCount = data?.data || 0;

  return (
    <IconButton
      aria-label="notes"
      icon={
        <Icon
          as={EmrNotes}
          color={
            sectionNotesCount && sectionNotesCount > 0 ? "blue" : "gray.450"
          }
          fontSize="1.25rem"
        />
      }
      variant="icon"
      fontSize="1.4375rem"
      minWidth="unset"
      onClick={(e) => {
        e.preventDefault();
        onOpen();
      }}
    />
  );
}

type CountersProps = {
  ordered: number;
  inProcess: number;
  completed: number;
  isLoading: boolean;
};

function Counters({ ordered, inProcess, completed, isLoading }: CountersProps) {
  const boxStyle: ChakraProps = {
    w: "20px",
    h: "20px",
    color: "white",
    borderRadius: "5px",
    textAlign: "center",
    fontSize: "14px",
  };

  return (
    <Flex justifyContent="space-between" gap={1} alignItems="center">
      {isLoading ? (
        <Loading spinnerProps={{ size: "sm", color: "blue" }} />
      ) : (
        <>
          <Box bgColor="orange" {...boxStyle}>
            {ordered}
          </Box>
          <Box bgColor="blue" {...boxStyle}>
            {inProcess}
          </Box>
          <Box bgColor="green" {...boxStyle}>
            {completed}
          </Box>
        </>
      )}
    </Flex>
  );
}

function ChartNavigationButton(props: ChartNavigationButtonProps) {
  const {
    label,
    labelIcon,
    sectionId,
    hasNotes,
    hasAudits,
    isShared,
    to,
    chartCode,
    encounterId,
    hasDiagrams,
    showIvMedicationCounters,
    showTreatmentsCounters,
    showOrderCounters,
    showEKGCounters,
    isSaved,
    hasPendingOrdersCancellation,
    showSignedSections,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  const fromAudits = location.pathname.includes("audits");

  const showNotes = hasNotes && fromAudits;
  const showAudits = hasAudits && fromAudits;
  const showGeneralNotesModal = showNotes && props.notesType === "general";
  const showSharedNotesModal = showNotes && props.notesType === "shared";
  const showOrderNotesModal = showNotes && props.notesType === "order";

  const { data: ivMedicationCounters, isLoading: ivMedicationCountersLoading } =
    useMedsAndIvCounters(encounterId, {
      enabled: showIvMedicationCounters,
    });

  const { data: treatmentsCounters, isLoading: treatmentsCountersLoading } =
    useTreatmentsCounters(encounterId, {
      enabled: showTreatmentsCounters,
    });

  const { data: orderCounters, isLoading: orderCountersLoading } =
    useEncounterOrderCounters(encounterId, showOrderCounters ?? "Lab", {
      enabled: Boolean(showOrderCounters),
    });

  const { data: ekgCounters, isLoading: ekgCountersLoading } = useEKGCounters(
    encounterId,
    {
      enabled: showEKGCounters,
    }
  );

  const { data: sectionIndicator } = useChartSidebarSectionIndicator({
    encounterId,
    chartCode,
    sectionCode: sectionId,
    enabled: !isShared && !isSaved,
  });

  const { data: sharedSectionIndicator } =
    useChartSidebarSharedSectionIndicator({
      encounterId,
      chartType: chartCode.includes("C") ? "Chart" : "Triage",
      sectionCode: sectionId,
      enabled: !!isShared && !isSaved,
    });

  const counters = {
    ordered:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.ordered
        : showEKGCounters
        ? ekgCounters?.data.ordered
        : showTreatmentsCounters
        ? treatmentsCounters?.data.ordered
        : orderCounters?.data.ordered) ?? 0,
    inProcess:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.inProcess
        : showEKGCounters
        ? ekgCounters?.data.inProcess
        : showTreatmentsCounters
        ? treatmentsCounters?.data.inProcess
        : orderCounters?.data.inProcess) ?? 0,
    completed:
      (showIvMedicationCounters
        ? ivMedicationCounters?.data.completed
        : showEKGCounters
        ? ekgCounters?.data.completed
        : showTreatmentsCounters
        ? treatmentsCounters?.data.completed
        : orderCounters?.data.completed) ?? 0,
    administered: showIvMedicationCounters
      ? ivMedicationCounters?.data.administered
      : 0,
  };

  return (
    <>
      <NavigationButton to={to} key={to}>
        <NavigationButtonContent
          label={label}
          labelIcon={labelIcon}
          isSaved={
            isSaved ||
            Boolean(sectionIndicator?.data.hasDataSaved) ||
            Boolean(sharedSectionIndicator?.data.hasDataSaved)
          }
        />
        {showIvMedicationCounters && !ivMedicationCountersLoading && (
          <Box
            bgColor="purple"
            sx={{
              w: "20px",
              h: "20px",
              color: "white",
              borderRadius: "5px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {counters.administered ?? 0}
          </Box>
        )}
        <HStack spacing="14px">
          {label === "Vitals" && <VitalsTimerBadge encounterId={encounterId} />}
          {hasDiagrams && !fromAudits && (
            <DiagramsButton encounterId={encounterId} chartCode={chartCode} />
          )}

          {showAudits &&
            (isShared ? (
              <AuditCommentsSharedSectionIndicator
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            ) : (
              <AuditCommentsSectionIndicator
                chartCode={chartCode}
                sectionCode={sectionId}
                encounterId={encounterId}
                isFromSideBar={true}
              />
            ))}

          {hasNotes && showNotes && (
            <ChartNotesButton
              onOpen={onOpen}
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionId}
            />
          )}

          {(showIvMedicationCounters ||
            Boolean(showOrderCounters) ||
            showEKGCounters ||
            showTreatmentsCounters) && (
            <Counters
              ordered={counters.ordered}
              inProcess={counters.inProcess}
              completed={counters.completed}
              isLoading={
                ivMedicationCountersLoading ||
                orderCountersLoading ||
                ekgCountersLoading ||
                treatmentsCountersLoading
              }
            />
          )}
          {hasPendingOrdersCancellation && (
            <Icon as={BubbleChatIndicator} color="red" fontSize="22px" />
          )}

          {showSignedSections && (
            <Box display="flex" gap={3}>
              <SignedSectionButton
                encounterId={encounterId}
                type="Discharge"
                icon={<Icon as={Home} fontSize="24px" />}
              />

              <SignedSectionButton
                encounterId={encounterId}
                type="Transfer"
                icon={<Icon as={Car} fontSize="28px" ml="6px" />}
              />
            </Box>
          )}
        </HStack>
      </NavigationButton>
      {showGeneralNotesModal && (
        <ChartNotesModal
          chartId={chartCode}
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionId={sectionId}
          title={label}
        />
      )}
      {showSharedNotesModal && (
        <SharedChartNotesModal
          isOpen={isOpen}
          encounterId={encounterId}
          onClose={onClose}
          sectionCode={sectionId}
          title={label}
        />
      )}
      {showOrderNotesModal && (
        <OrderChartNotesModal
          isOpen={isOpen}
          onClose={onClose}
          encounterId={encounterId}
          chartCode={chartCode || ""}
          orderType={props.orderType}
          title={label}
        />
      )}
    </>
  );
}

function MoveToBillingButton() {
  const { encounterId = "" } = useParams<{ encounterId: string }>();

  const { pathname } = useLocation();

  const type = findChartTypeFromPathname(pathname);

  const {
    mutateAsync: updateAuditStatus,
    isLoading: isUpdateAuditStatusLoading,
  } = useUpdateAuditStatus(encounterId);

  const {
    isOpen: isBillingWarningOpen,
    onClose: onBillingWarningClose,
    onOpen: onBillingWarningOpen,
  } = useDisclosure();

  const toast = useToast();

  const onCompleteAudit = async () => {
    try {
      await updateAuditStatus({
        status: "Completed",
        type,
      });
      onBillingWarningClose();
      toast({
        description: "Your Audit Review has been marked as complete.",
      });
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <>
      <Button
        variant="outlineSquared"
        borderRadius="10px"
        color="green"
        colorScheme="green"
        sx={{ borderColor: "green" }}
        justifySelf="center"
        width="100%"
        minW="100%"
        mb="1rem"
        onClick={onBillingWarningOpen}
        isDisabled={isUpdateAuditStatusLoading}
      >
        Move to Billing
      </Button>

      <WarningDialog
        isOpen={isBillingWarningOpen}
        onCancel={onBillingWarningClose}
        title="Warning!"
        mainText={
          <chakra.span>
            Are you sure you want to move this to billing?
            <br />
            Moving this to Billing means you will complete this Audit. You will
            not be able to edit this chart again.
          </chakra.span>
        }
        onClose={onBillingWarningClose}
        onAction={onCompleteAudit}
        cancelLabel="Cancel"
        actionLabel="Complete Audit"
        blockScrollOnMount={false}
        cancelButtonProps={{ color: "red" }}
        actionButtonProps={{ color: "blue" }}
        actionsDirection="vertical"
        isActionLoading={isUpdateAuditStatusLoading}
      />
    </>
  );
}

function ChartsSidebar(props: ChartsSidebarProps) {
  const { children } = props;
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { isPastEncounter } = useChartTrackingContext();
  const navigate = useNavigate();
  const { scope } = usePermissions();
  const location = useLocation();

  const createChart = new URLSearchParams(location.search).get("createChart");

  const [isEditMode, setIsEditMode] = React.useState(!!createChart);
  const [hasActiveCharts, setHasActiveCharts] = React.useState(false);
  const { ref, scrollbarWidth } = useScrollbarWidth<HTMLDivElement>();
  const state = location.state as {
    fromLabel?: string;
    fromRoute?: string;
  } | null;
  const contentPadding = `12px ${24 - scrollbarWidth}px 8px 24px`;

  const fromAudits = location.pathname.includes("audits");
  const fromLabs = location.pathname.includes("labs");
  const fromBillings = location.pathname.includes("billings");
  const fromRadiology = location.pathname.includes("radiology");
  const fromInProcess = location.pathname.includes("in-process");
  const fromOpenCharts = location.pathname.includes("open-charts");

  const fromLabel = fromAudits
    ? "Audits"
    : fromInProcess
    ? "InProcess"
    : fromLabs
    ? "Labs"
    : fromBillings
    ? "Billings"
    : fromOpenCharts
    ? "Open Charts"
    : fromRadiology
    ? "Radiology"
    : "InProcess";
  const fromRoute = fromAudits
    ? "/audits"
    : fromInProcess
    ? "/in-process"
    : fromLabs
    ? "/labs"
    : fromBillings
    ? "/billings"
    : fromOpenCharts
    ? "/open-charts"
    : fromRadiology
    ? "/radiology"
    : "/in-process";

  const addPermission =
    scope("nurse:add").isAccessible || scope("provider:add").isAccessible;

  return (
    <Sidebar>
      <SidebarActions>
        {!isPastEncounter && (
          <BackButton
            onClick={() =>
              navigate(state?.fromRoute ? state?.fromRoute : fromRoute, {
                replace: true,
              })
            }
          >
            {state?.fromLabel ? state?.fromLabel : fromLabel}
          </BackButton>
        )}
        <ChartSectionHeaderMenu encounterId={encounterId} />
      </SidebarActions>
      <SidebarHeader padding="0 17px">
        {fromLabel === "Audits" && <MoveToBillingButton />}
        <SidebarChartPatientCard patientId={encounterId} marginBottom="10px" />
      </SidebarHeader>
      <SidebarContent ref={ref} padding={contentPadding} overflow="hidden">
        <Flex
          direction="column"
          height="100%"
          sx={{
            // height: "100%",
            overflowX: "hidden",
          }}
        >
          <ChartPicker
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            setHasActiveCharts={setHasActiveCharts}
          />
          <Divider
            margin="10px -7px 10px -7px"
            borderColor="gray.500"
            width="unset"
          />
          {isEditMode && addPermission ? (
            <AddNewChartButton
              encounterId={encounterId}
              defaultIsOpen={{
                value: !!createChart || !hasActiveCharts,
                chartType: createChart || "demographics",
              }}
            />
          ) : (
            <Box
              sx={{
                flex: "1 1 auto",
                minHeight: "auto",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {children}
            </Box>
          )}
        </Flex>
      </SidebarContent>
      <SidebarBottom>
        <FaqButton />
        <SidebarUserView />
      </SidebarBottom>
    </Sidebar>
  );
}

export type { ChartsSidebarProps, ChartNavigationButtonProps };
export { ChartsSidebar, ChartNavigationButton };
