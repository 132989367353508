/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
  HStack,
  VStack,
  chakra,
} from "@chakra-ui/react";
import {
  Button,
  Card,
  Label,
  Loading,
  UserAvatar,
  WarningDialog,
} from "shared";

import {
  HARD_STOPS_TRIAGE_SECTIONS,
  HARD_STOPS_PROVIDER_SECTIONS,
  HARD_STOPS_SUBSECTIONS,
  HARD_STOPS_SECTIONS_ROUTES,
} from "./constants";
import { ProviderHardStopsResponse, TriageHardStopsResponse } from "./types";
import { useNavigate } from "react-router-dom";
import { CheckCircleHardStops, Clear, ExclamationCircleHardStops } from "icons";
import {
  useChartDispositionInfo,
  useChartHardStops,
  useUpdateChartApplicationStatus,
} from "modules/charts-shared/api";
import { useGetUser } from "api/queries";
import { useFormContext } from "react-hook-form";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { ChartType } from "types";
import { ProviderChartCode, TriageChartCode } from "enums";
import { usePermissions } from "contexts";
import { format } from "date-fns";

const SECTION_CODE = "SDIS";

type HardStops =
  | TriageHardStopsResponse
  | ProviderHardStopsResponse
  | undefined;

type SidebarHardStopProps = {
  patientDispositioned: boolean;
  chartCode: TriageChartCode | ProviderChartCode;
  chartType: ChartType;
  encounterId: string;
};

type ChartHardStopsSectionProps = {
  key: number;
  sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS;
  section:
    | keyof typeof HARD_STOPS_PROVIDER_SECTIONS
    | keyof typeof HARD_STOPS_TRIAGE_SECTIONS;
  data: HardStops;
  showGoToLabel?: boolean;
};

const validateElementRestrictions = (section: string, data: HardStops) => {
  const restrictions = {
    medsAndIV: data?.hasMedAndIvOrders,
    ekg: data?.hasEKGOrders,
    labs: data?.hasLabsOrders,
    treatments: data?.hasTreatmentsOrders,
    radiology: data?.hasRadiologyOrders,
  };

  const prop = section as keyof typeof restrictions;
  return restrictions[prop] ?? true;
};

function ChartHardStopsSection(props: ChartHardStopsSectionProps) {
  const { key, section, sections, data, showGoToLabel = false } = props;
  const element = data ? data[section as keyof typeof data] : undefined;

  const navigate = useNavigate();

  if (
    typeof element === "undefined" ||
    !validateElementRestrictions(section, data)
  )
    return null;

  return (
    <Box key={key}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Label ml="25px" fontWeight="500" color="black">
          {sections[section as keyof typeof sections]}
        </Label>

        {showGoToLabel && (
          <Button
            variant="label"
            onClick={() =>
              navigate(
                `./../${
                  HARD_STOPS_SECTIONS_ROUTES[
                    section as keyof typeof HARD_STOPS_SECTIONS_ROUTES
                  ]
                }`
              )
            }
            color="blue"
          >
            Go to Section
          </Button>
        )}
      </Box>

      <Card
        sx={{
          width: "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 1.5,
          px: 5,
          flexDirection: "column",
          boxShadow: "none",
        }}
      >
        {Object.keys(element).map((prop, index) => {
          const value = element[prop as keyof typeof element];
          if (typeof value === "undefined") return null;

          return (
            <Flex w="100%" columnGap="17px" alignItems="center" key={index}>
              <Icon
                as={value ? CheckCircleHardStops : ExclamationCircleHardStops}
                alignSelf="center"
                fontSize={"26px"}
                color={value ? "green" : "red"}
              />
              <Flex
                borderBottom={
                  index === Object.keys(element).length - 1
                    ? "0px"
                    : "1px solid"
                }
                borderColor="gray.450"
                w="100%"
                justify="start"
                p="1rem 0 1rem 1rem"
                alignItems="center"
                fontWeight="500"
              >
                {
                  HARD_STOPS_SUBSECTIONS[
                    prop as keyof typeof HARD_STOPS_SUBSECTIONS
                  ]
                }
              </Flex>
            </Flex>
          );
        })}
      </Card>
    </Box>
  );
}

function SidebarHardStops(props: SidebarHardStopProps) {
  const { encounterId, chartCode, chartType, patientDispositioned } = props;
  const toast = useToast();
  const { scope } = usePermissions();
  const { getValues } = useFormContext();
  const [allowFetch, setAllowFetch] = React.useState(false);

  const {
    data: hardStops,
    isLoading: isLoadingHardStops,
    isFetching: isFetchingHardStops,
    refetch: refetchHardStops,
  } = useChartHardStops({
    chartCode,
    chartType,
    encounterId,
    enabled: allowFetch,
  });

  const {
    data: dispositionInfo,
    isLoading: isLoadingDispositionInfo,
    isFetching: isFetchingDispositionInfo,
    refetch: refetchDispositionInfo,
  } = useChartDispositionInfo({
    chartCode,
    encounterId,
    enabled: allowFetch,
  });

  const { chartStatus, chartCompletedBy, chartCompletedAt } =
    React.useMemo(() => {
      if (!dispositionInfo)
        return {
          chartStatus: null,
          chartCompletedBy: null,
          chartCompletedAt: null,
        };

      return {
        chartStatus: dispositionInfo.data?.chartStatus,
        chartCompletedBy: dispositionInfo.data?.chartCompletedBy,
        chartCompletedAt: dispositionInfo.data?.chartCompletedAt,
      };
    }, [dispositionInfo]);

  const isLoading = React.useMemo(
    () =>
      isLoadingHardStops ||
      isFetchingHardStops ||
      isLoadingDispositionInfo ||
      isFetchingDispositionInfo,
    [
      isLoadingHardStops,
      isFetchingHardStops,
      isLoadingDispositionInfo,
      isFetchingDispositionInfo,
    ]
  );

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogSubMessage, setWarningDialogSubMessage] =
    React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<() => void>();

  const pendingHardStops = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart"
        ? hardStops?.data?.providerHardStops
        : hardStops?.data?.triageHardStops;

    if (!hardStopsData) return 0;

    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).reduce((count, [_key, value]) => {
      if (!validateElementRestrictions(_key, hardStopsData)) return count;

      return count + Object.values(value).filter((v) => !v).length;
    }, 0);
  }, [chartType, hardStops?.data]);

  const areAllHardStopsTrue = React.useMemo(() => {
    const hardStopsData =
      chartType === "Chart"
        ? hardStops?.data?.providerHardStops
        : hardStops?.data?.triageHardStops;

    if (!hardStopsData) return false;

    // Ignore this properties when validating
    const {
      hasEKGOrders,
      hasLabsOrders,
      hasMedAndIvOrders,
      hasRadiologyOrders,
      hasTreatmentsOrders,
      ...rest
    } = hardStopsData;

    return Object.entries(rest).every(([_key, value]) => {
      if (!validateElementRestrictions(_key, hardStopsData)) return true;

      return Object.values(value).every((v) => !!v);
    });
  }, [chartType, hardStops?.data]);

  const {
    mutateAsync: updateChartStatus,
    isLoading: isLoadingChartStatusUpdate,
  } = useUpdateChartApplicationStatus({
    encounterId,
    chartCode,
    sectionId: SECTION_CODE,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChangeChartStatusToComplete = async () => {
    try {
      await updateChartStatus("Completed");
      toast({ description: formMessages.updateSuccess("ChartStatus") });

      onCloseWarningDialog();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onCompleteChartAction = () => {
    if (!areAllHardStopsTrue && scope("provider:compchart").isAccessible) {
      setWarningDialogMessage(
        "Are you sure you want to mark this Chart as Complete?"
      );
      setWarningDialogSubMessage(
        "There are pending hard stops. override and mark as complete?"
      );
    } else {
      setWarningDialogMessage(
        "Are you sure you want to mark this Chart as Complete?"
      );
      setWarningDialogSubMessage("This action cannot be undone.");
    }
    setWarningDialogAction(() => handleChangeChartStatusToComplete);
    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    onClose();
  };

  const sections:
    | typeof HARD_STOPS_PROVIDER_SECTIONS
    | typeof HARD_STOPS_TRIAGE_SECTIONS =
    chartType === "Triage"
      ? HARD_STOPS_TRIAGE_SECTIONS
      : HARD_STOPS_PROVIDER_SECTIONS;

  return (
    <Box
      width="25vw"
      bg="gray.200"
      height="100%"
      position="fixed"
      top="0"
      right="0"
      display="flex"
      flexDirection="column"
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Text
            sx={{
              paddingLeft: "15px",
              fontWeight: "600",
              fontSize: "1.2625rem",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {chartType === "Triage" ? "Nurse" : "Provider"} Chart Hard Stops
          </Text>
          <Badge
            bg="white"
            color="red"
            fontWeight="bold"
            borderRadius="10px"
            padding="0.325rem 0.8rem"
          >
            {isLoading ? "-" : pendingHardStops}
          </Badge>
        </Box>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          padding: "20px",
          height: chartStatus === "Completed" ? "80%" : "90%",
          overflowY: "auto",
          flexDirection: "column",
          justifyContent: isLoading ? "center" : "flex-start",
          backgroundColor: "gray.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            Object.keys(sections).map((key, index) => (
              <ChartHardStopsSection
                key={index}
                showGoToLabel
                sections={sections}
                section={key as keyof typeof HARD_STOPS_PROVIDER_SECTIONS}
                data={
                  chartType === "Chart"
                    ? hardStops?.data?.providerHardStops
                    : hardStops?.data?.triageHardStops
                }
              />
            ))
          )}
        </Box>
      </Box>

      <Box backgroundColor={"gray.400"}>
        <Divider />
        <Flex
          justifyContent="center"
          width="100%"
          pt={2}
          pb={2}
          gap={30}
          sx={{
            backgroundColor: "white",
          }}
        >
          <Badge
            bg="transparent"
            color="red"
            borderRadius="20px"
            padding="0.325rem .85rem"
            fontSize=".875rem"
            textTransform="capitalize"
          >
            {patientDispositioned
              ? "Patient Dispositioned"
              : "Patient Not Dispositioned"}
          </Badge>
        </Flex>
        <Box>
          {chartStatus !== "Completed" ? (
            <Flex
              justifyContent="center"
              width="100%"
              pt={4}
              pb={4}
              px={2}
              gap={2}
            >
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                width="240px"
                onClick={() => {
                  if (!allowFetch) setAllowFetch(true);
                  else {
                    refetchHardStops();
                    refetchDispositionInfo();
                  }
                }}
                isLoading={isLoading}
                color="gray.650"
                sx={{ borderColor: "gray.650" }}
              >
                <Clear sx={{ transform: "scaleX(-1)", mr: 2 }} />
                Refresh Hardstops
              </Button>
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                width="240px"
                isDisabled={
                  !allowFetch ||
                  (scope("provider:compchart").isAccessible
                    ? isLoadingChartStatusUpdate
                    : !scope(
                        chartType === "Chart" ? "provider:disp" : "nurse:disp"
                      ).isEditable ||
                      isLoadingChartStatusUpdate ||
                      areAllHardStopsTrue === false)
                }
                onClick={onCompleteChartAction}
                isLoading={isLoadingChartStatusUpdate}
              >
                Chart Complete
              </Button>

              <WarningDialog
                isOpen={isOpen}
                onCancel={onCloseWarningDialog}
                title="Warning"
                mainText={warningDialogMessage}
                secondaryText={warningDialogSubMessage}
                onClose={onCloseWarningDialog}
                onAction={() => warningDialogAction && warningDialogAction()}
                cancelLabel="No"
                actionLabel="Yes"
                blockScrollOnMount={false}
                cancelButtonProps={{ color: "red" }}
                actionButtonProps={{ color: "blue" }}
              />
            </Flex>
          ) : (
            <Box
              bg="white"
              py={2}
              px={4}
              m={6}
              borderRadius="10px"
              boxShadow="md"
            >
              <Flex justifyContent="space-between" mb={0.5} alignItems="center">
                <Text color="gray.600">Marked Complete by</Text>
                <Text>
                  <HStack spacing={0}>
                    <UserAvatar
                      marginRight="0.875rem"
                      size="xs"
                      fontSize=".75rem"
                      picture={chartCompletedBy?.pictureUrl ?? undefined}
                    />
                    <VStack spacing="0px" alignItems="start">
                      <chakra.h3
                        fontSize="1.0625rem"
                        fontWeight="600"
                        textAlign="left"
                      >
                        {chartCompletedBy?.firstName}{" "}
                        {chartCompletedBy?.lastName}
                      </chakra.h3>
                      <Text fontSize=".9625rem" color="gray.600">
                        {chartCompletedBy?.title}
                      </Text>
                    </VStack>
                  </HStack>
                </Text>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between" mt={2}>
                <Text color="gray.600">Date / Time</Text>
                <Text fontSize=".9625rem">
                  {chartCompletedAt &&
                    format(new Date(chartCompletedAt), "LL/dd/yyyy hh:mm")}
                </Text>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export type { HardStops, SidebarHardStopProps, ChartHardStopsSectionProps };
export { SidebarHardStops, ChartHardStopsSection, validateElementRestrictions };
